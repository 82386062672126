<template>
  <div class="input_section">
    <p
      :id="`radio_group_${tagId}`"
      class="input_name"
    >
      {{ label_name }}
      <span v-if="filling_status">
        <img
          v-if="value !== $t('general.statuses.no_data') && value"
          class="checkmark"
          src="@/assets/img/green-check.svg"
          alt=""
        />
        <img
          v-else
          class="checkmark no_filled"
          src="@/assets/img/black-check.svg"
          alt=""
        />
      </span>
    </p>

    <div class="radio_group">
      <div
        v-for="(el, index) in variables"
        :key="index"
        style="width: 100%"
      >
        <input
          :id="`${tagId}_radio_${index}`"
          v-model="current_value"
          class="new_radio"
          type="radio"
          :value="el.value"
          @change="$emit('update', field_name, current_value)"
        />
        <label :for="`${tagId}_radio_${index}`"
          ><p>{{ el.name }}</p></label
        >
      </div>
    </div>
    <small
      v-if="hint"
      class="input_hint"
      >{{ hint }}</small
    >
  </div>
</template>

<script>
/* 
This element get v-model string and some response variables

Examples:
1. v-model is string
<radio-group v-model="process.have_teens"
             field_name="Могут ли пользователи иметь доступ?"
             variables=[{value: 'могут',  name: 'могут'}, {value: "не могут", name: "не могут"}]></radio-group>
*/
import { mapState } from 'vuex'

export default {
  name: 'RadioGroupEmit',

  props: {
    field_name: String,
    label_name: String,
    variables: Array,
    hint: String,
    value: {},
    tagId: {
      type: String,
      default: '',
    },
    filling_status: Boolean,
    status: String,
  },

  data() {
    return {
      current_value: this.value,
    }
  },

  watch: {
    value() {
      this.current_value = this.value
    },
  },

  computed: {
    ...mapState('company', ['company']),
  },
}
</script>
