import router from '@/router'

export default {
  manageError({ commit }, error) {
    if (String(error) === 'Error: Network Error') {
      commit('DANGER_TOAST', 'Проблемы с подключением к интернету, проверь Ваш источник подключения.')
    }
    if (error.response.status === 400 && !error.response.data.mail) {
      commit('DANGER_TOAST', 'Ошибка при заполнении данных, обратитесь к администратору')
    }
    if (error.response.status === 500) {
      commit('DANGER_TOAST', 'Ошибка при заполнении данных, обратитесь к администратору')
    }
  },

  // handle errors from all functions
  errorProcessing(error) {
    return new Promise(resolve => {
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login')
        resolve('Закончилась сессия, аутентифицируйтесь заново.')
      } else if (error.response.status === 404) {
        router.push('/404')
        resolve('Объект не найден.')
      } else if (error.response.status === 400) {
        // if error in rus => return rus error
        if (/[а-я]/i.test(error.response.data)) {
          resolve(error.response.data)
        }

        resolve('Ошибка при создании/заполнении объектов.')
      } else {
        resolve(
          'Ошибка при обращении к серверу. Скорее всего мы уже знаем о проблеме и занимаемся ей. ' +
            'Если необходима помощь или хотите сообщить о проблеме - Telegram: @Andrey_Kryukov.',
        )
      }
    })
  },
}
