import i18n from '@/locales'
import { api } from '@/main'

export default {
  // Process actions
  async fetchDetails(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/${payload.selected_block}/details/`,
      context,
      {},
      'SET_DETAILS',
      i18n.global.t('dashboard.loading_error'),
    )
  },
}
