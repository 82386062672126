<template>
  <section :class="['input_section']">
    <label
      v-click-outside="closeComment"
      :for="`input_id_${unique_id}`"
    >
      <p class="input_name"
         :id="`label_${unique_id}`"
      >
        {{ label_name }}
        <span
          v-if="required"
          style="color: red"
          >*</span
        >
        <span v-if="filling_status">
          <img
            v-if="value"
            class="checkmark"
            src="@/assets/img/green-check.svg"
            alt=""
          />
          <img
            v-else
            class="checkmark no_filled"
            src="@/assets/img/black-check.svg"
            alt=""
          />
        </span>
      </p>
      <div :class="['text_input', validation_error ? 'input_error' : '']">
        <input
          :id="`input_id_${unique_id}`"
          :type="type"
          :value="value"
          :required="required"
          :placeholder="placeholder"
          @input="addEmail($event)"
        />

        <div
          v-if="user.is_analyst && comment"
          class="icon"
        >
          <img
            src="@/assets/img/add_comment.svg"
            alt="add_comment"
            @click="show_comment = true"
          />
          <div
            v-if="comments_sorted.length"
            class="number_of_comments"
          >
            {{ comments_sorted ? comments_sorted.length : '' }}
          </div>
        </div>
      </div>
      <small
        v-if="hint"
        class="input_hint"
        >{{ hint }}</small
      >

      <div
        v-if="show_comment && comment && !user.is_client"
        class="comment_board"
      >
        <form @submit.prevent="addCommentHandle()">
          <div class="add_comment">
            <input
              v-model="new_comment.text"
              required
              :placeholder="$t('general.add_comment')"
            />

            <div class="comment_buttons">
              <div
                :class="['add_button', is_comment_filled ? 'success' : '']"
                @click="addCommentHandle()"
              >
                {{ $t('general.add') }}
              </div>
            </div>
          </div>
        </form>

        <!-- already created comments -->
        <div class="created_comments">
          <div
            v-for="(c, index) in comments_sorted"
            :key="index"
            class="detail_comment"
          >
            <!--            <div class="user">-->
            <!--              <div class="user_avatar">{{ c.create_by }}</div>-->
            <!--            </div>-->

            <div class="text">
              <div class="comment_title">
                <div
                  v-for="create_by in createByUser(c.create_by)"
                  :key="create_by.id"
                  class="info"
                >
                  <span class="name">{{ create_by.username }}</span>
                  <span class="date">{{ c.created.split('T')[0] }}</span>
                </div>

                <div class="action">
                  <button
                    class="delete_button"
                    type="button"
                    @click="delComment(c.id)"
                  >
                    {{ $t('general.delete') }}
                  </button>
                </div>
              </div>

              <p class="comment_text">
                {{ c.text }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p
        v-if="validation_error"
        class="error_text"
      >
        {{ validation_error }}
      </p>

      <delete-modal
        :show="show_delete_comment"
        title="Удаление комментария"
        desc="Вы уверены, что хотите удалить данный комментарий?"
        @approve="handleDeleteComment"
        @close="show_delete_comment = false"
      ></delete-modal>
    </label>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { throttle } from 'lodash'
import DeleteModal from '@/components/global/modals/deleteModal.vue'

export default {
  name: 'EmailInputEmit',
  components: { DeleteModal },

  props: {
    value: {
      type: [String, Number],
    },
    field_name: {
      type: String,
    },
    tagId: {
      type: String,
      default: '',
    },
    label_name: String,
    placeholder: String,
    required: Boolean,
    error: String,
    type: {
      type: String,
      default: 'text',
    },
    hint: {
      type: String,
    },
    comment: String,
    query_param: Number,
    obj: String,
    filling_status: Boolean,
  },

  emits: ['update'],

  data() {
    return {
      unique_id: '',
      validation_error: '',
      new_comment: {
        text: '',
      },
      show_delete_comment: false,
      current_comment_index: 0,

      show_comment: false,
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    ...mapState(['company']),
    ...mapState('company', ['company', 'comments', 'users']),
    ...mapState('process', ['process']),
    ...mapState('system', ['system']),
    ...mapState('user', ['user']),

    is_comment_filled() {
      return !!this.new_comment.text.length
    },

    comments_sorted() {
      let comment = this.comment
      let comments_sorted = [...this.comments]
      return comments_sorted.filter(c => c.field_name === comment && c.object_id === this.get_object_id)
    },

    get_object_id() {
      return this.obj === 'process' ? this.process.id : this.system.id
    },
  },

  methods: {
    ...mapActions('company', ['addComments', 'deleteComment']),

    closeComment() {
      this.show_comment = false
    },

    handleDeleteComment() {
      let cid = this.company.id
      let ci = this.current_comment_index
      this.deleteComment({ current_index: ci, company_id: cid })
      this.show_delete_comment = false
      this.show_comment = true
    },

    addCommentHandle() {
      this.show_comment = true
      if (this.new_comment.text?.length) {
        this.addComments({
          content_type: 9,
          create_by: parseInt(this.user.id),
          field_name: this.field_name,
          object_id: this.get_object_id,
          text: this.new_comment.text,
          company_id: this.company.id,
        })
      }
      this.new_comment.text = ''
    },

    createByUser(id) {
      return this.users.filter(user => user.id === id)
    },

    addEmail: throttle(function (e) {
      if (
        !e.target.value.length ||
        e.target.value.match(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/g,
        )
      ) {
        this.$emit('update', this.field_name, e.target.value)
        this.validation_error = ''
      } else {
        this.validation_error = this.$t('general.errors.add_email')
      }
    }, 3000),

    delComment(id) {
      this.show_delete_comment = true
      this.current_comment_index = id
    },
  },
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>
