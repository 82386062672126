export default {
  percent(state) {
    // Возвращает процент заполнения СЗИ
    const required_fields = [
      'description',
      'responsible_admin',
      'responsible_email',
      'version',
      'developer_name',
      'tool_status',
      'doc_number',
      'license_number',
      'count_security_tools',
      'tool_types',
      'screen',
    ]
    const required_count = required_fields.length
    const filled = required_fields.filter(key => state.security[key]).length
    return Math.round(filled / (required_count / 100))
  },
}
