export function datetimeToString(datetime) {
  try {
    const date = new Date(datetime)
    return date.toLocaleString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' })
  } catch {
    return null
  }
}

export function dateToISODate(datetime) {
  try {
    const date = new Date(datetime)
    return date.toISOString().split('T')[0]
  } catch {
    return null
  }
}
