<template>
  <section :class="['input_section']">
    <label
      v-click-outside="closeComment"
      :for="`input_id_${unique_id}`"
    >
      <p class="input_name"
         :id="`label_name_${unique_id}`"
      >
        {{ label_name }}
        <span
          v-if="required"
          style="color: red"
          >*</span
        >
      </p>
      <div :class="['text_input', validation_error ? 'input_error' : '']">
        <input
          :id="`input_id_${unique_id}`"
          class="none_border"
          :type="type"
          :value="value"
          :required="required"
          :placeholder="placeholder"
          @input="addIpAddress($event)"
        />
      </div>
      <small
        v-if="hint"
        class="input_hint"
        >{{ hint }}</small
      >
      <p
        v-if="validation_error"
        class="error_text"
      >
        {{ validation_error }}
      </p>
    </label>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { throttle } from 'lodash'

export default {
  name: 'IpAddressEmit',

  props: {
    value: {
      type: [String, Number],
    },
    field_name: {
      type: String,
    },
    tagId: {
      type: String,
      default: '',
    },
    label_name: String,
    placeholder: String,
    required: Boolean,
    error: String,
    type: {
      type: String,
      default: 'id_address',
    },
    hint: {
      type: String,
    },
  },

  emits: ['update'],

  data() {
    return {
      unique_id: '',
      validation_error: '',
      show_delete_comment: false,

      show_comment: false,
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  methods: {
    ...mapMutations(['SUCCESS_TOAST', 'DANGER_TOAST']),
    ...mapActions('company', ['addComments', 'deleteComment']),

    closeComment() {
      this.show_comment = false
    },

    addIpAddress: throttle(function (e) {
      if (
        !e.target.value.length ||
        e.target.value.match(
          /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/,
        )
      ) {
        this.$emit('update', this.field_name, e.target.value)
        this.validation_error = ''
      } else {
        this.validation_error = this.$t('general.errors.add_ip_address')
      }
    }, 3000),
  },
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>
