export default {
  systems: [],
  total_systems: 0,
  system: {
    is_third_parties: false,
    third_parties: [],
  },
  others: [],
  system_subjects: [],

  system_third_parties: [],
  system_third_party_subjects: [],
  system_interactions: [],
}
