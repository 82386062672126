export default {
  root_system_subjects(state) {
    // Выбираем всех субъектов, которые привязаны к системе в 3-ем разделе, но не во втором
    return state.system_subjects.filter(s => {
      return !s.link_process && !s.link_site && !s.link_app && !s.link_other && !s.link_system
    })
  },

  percent(state) {
    const requires_system = new Set([
      //#1
      'description',
      'developer',
      'responsible_admin',
      //#3
      'delete_data_variant',
      //#5
      'db_country_place',
      'system_address',
    ])

    const systemKeys = Object.keys(state.system)
    let totalsFilled = 0

    systemKeys.forEach(key => {
      if (requires_system.has(key)) {
        const value = !!state.system[key]
        const system_key = state.system[key]
        if (value) {
          if (['string', 'number', 'boolean'].includes(typeof system_key)) {
            totalsFilled++
          }
          if (['object'].includes(typeof system_key)) {
            Object.keys(system_key).length !== 0 ? totalsFilled++ : ''
          }
        }
      }
    })
    let result = requires_system.size
    result = Math.round((totalsFilled / result) * 100)

    return Math.max(Math.min(result, 100), 0)
  },
}
