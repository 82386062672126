<template>
  <div class="input_section">
    <p class="input_name">{{ field_name }}</p>

    <div class="radio_group">
      <div
        v-for="(el, index) in variables"
        :key="index"
        style="width: 100%"
      >
        <input
          :id="`${unique_id}_radio_${index}`"
          v-model="current_value"
          class="new_radio"
          type="radio"
          :value="el.value"
          @change="$emit('update:modelValue', $event.target._value)"
        />
        <label :for="`${unique_id}_radio_${index}`"
          ><p>{{ el.name }}</p></label
        >
      </div>
    </div>
    <small
      v-if="hint"
      class="input_hint"
      >{{ hint }}</small
    >
  </div>
</template>

<script>
/* 
This element get v-model string and some response variables

Examples:
1. v-model is string
<radio-group v-model="process.have_teens"
             field_name="Могут ли пользователи иметь доступ?"
             variables=[{value: 'могут',  name: 'могут'}, {value: "не могут", name: "не могут"}]></radio-group>
*/
export default {
  name: 'radio-group',

  props: {
    modelValue: {
      type: [String, Boolean],
    },
    field_name: String,
    variables: Array,
    hint: String,
    tagId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      current_value: '',
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  mounted() {
    this.current_value = this.modelValue
  },

  computed: {
    random_number() {
      return String(Math.random()).split('.')[1]
    },
  },
}
</script>
