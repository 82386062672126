import AutoComplete from './AutoComplete'
import AutoCompleteEmit from './AutoCompleteEmit'
import DocumentsInput from '@/components/global/inputs/DocumentsInput.vue'
import DocumentLinkInput from '@/components/global/inputs/DocumentLinkInput'
import EmailInputEmit from '@/components/global/inputs/EmailInputEmit'
import TextInputEmit from '@/components/global/inputs/TextInputEmit'
import TagInput from './TagInput'
import TagInputObject from './TagInputObject'
import TagInputEmit from '@/components/global/inputs/TagInputEmit'
import TagEmailEmit from '@/components/global/inputs/TagEmailEmit'
import TextInput from '@/components/global/inputs/TextInput.vue'
import TextareaInputEmit from '@/components/global/inputs/TextareaInputEmit'
import SelectInput from './SelectInput'
import RadioGroup from './RadioGroup'
import RadioGroupEmit from './RadioGroupEmit'
import RadioGroupList from './RadioGroupList'
import MultiSelectInput from './MultiSelectInput.vue'
import TieredSelectInput from './TieredSelectInput.vue'
import IpAddressEmit from './IpAddressEmit.vue'
import TagDeleteObject from './TagDeleteObject.vue'

export default [
  AutoComplete,
  AutoCompleteEmit,
  DocumentLinkInput,
  DocumentsInput,
  EmailInputEmit,
  TextInput,
  TextInputEmit,
  TagInput,
  TagInputObject,
  TagInputEmit,
  TagEmailEmit,
  TextareaInputEmit,
  SelectInput,
  RadioGroup,
  RadioGroupEmit,
  RadioGroupList,
  MultiSelectInput,
  TieredSelectInput,
  IpAddressEmit,
  TagDeleteObject,
]
