import { datetimeToString } from '@/services/date'
import i18n from '@/locales'
export default {
  last_company_id(state) {
    const defaultCompanyId = Number(localStorage.getItem('defaultCompanyId'))
    const companyExists = state.companies?.some(company => company.id === defaultCompanyId)
    if (defaultCompanyId && companyExists) {
      return defaultCompanyId
    }
    try {
      return state.companies[0].id === 962 ? state.companies[1]?.id : state.companies[0]?.id
    } catch (e) {
      return 1
    }
  },

  main_forms(state) {
    return [
      { ...state.information, form_name: 'Информация о компании', route_name: 'information' },
      { ...state.site_app, form_name: 'Сайты и мобильные приложения компании', route_name: 'siteappunion' },
      {
        ...state.commission,
        form_name: 'Ответственные за обработку и защиту персональных данных',
        route_name: 'commission',
      },
      {
        ...state.department,
        form_name: 'Отделы и должности, работающие с персональными данными',
        route_name: 'department',
      },
    ]
  },

  company_positions(state) {
    let pos_list = Array()
    if (!state.department.positions) {
      return pos_list
    } else {
      state.department.positions.split(';').forEach(el => {
        if (el.length) {
          pos_list.push({ name: el.trim() })
        }
      })
      return pos_list
    }
  },

  // Справочники
  all_directory_items(state) {
    return Array.from(state.directory_items, el => el.name)
  },

  other_directory_items(state) {
    return Array.from(
      state.directory_items.filter(el => el.category === 'other_personal_data'),
      item => item.name,
    )
  },

  spec_directory_items(state) {
    return Array.from(
      state.directory_items.filter(el => el.category === 'special_category_data'),
      item => item.name,
    )
  },

  bio_directory_items(state) {
    return Array.from(
      state.directory_items.filter(el => el.category === 'biometric_personal_data'),
      item => item.name,
    )
  },

  percentInformation(state) {
    const requires_info = new Set([
      'contact_phone',
      'correction_date',
      'full_name',
      'inn',
      'legal_address',
      'mail',
      'manager_name',
      'manager_position',
      'ogrn',
      'okved',
      'registration_date',
      'registry_status',
      'responsible_email',
      'settlement',
      'short_name',
      'type_of_business',
    ])

    const informationKeys = Object.keys(state.information)
    let totalsFilled = 0

    informationKeys.forEach(key => {
      if (requires_info.has(key)) {
        const value = !!state.information[key]
        value ? totalsFilled++ : ''
      }
    })

    let result = requires_info.size
    result = Math.round((totalsFilled / result) * 100)

    return Math.max(Math.min(result, 100), 0)
  },

  percentSiteApp(state) {
    return state.sites?.length ? 100 : 0
  },

  percentCommission(state) {
    const requires = new Set(['a_name', 'a_phone', 'a_position', 'o_mail', 'o_name', 'o_phone', 'o_position'])

    const commissionKeys = Object.keys(state.commission)
    let totalsFilled = 0

    commissionKeys.forEach(key => {
      if (requires.has(key)) {
        const value = !!state.commission[key]
        value ? totalsFilled++ : ''
      }
    })
    let result = requires.size
    result = Math.round((totalsFilled / result) * 100)
    return Math.max(Math.min(result, 100), 0)
  },

  percentDepartment(state) {
    return state.department.positions ? 100 : 0
  },

  getComment: state => id => {
    if (state.comments?.length) {
      return state.comments.filter(c => c.object_id === id)
    }
  },

  //Ссылка по ключу активна до
  expiration_date(state) {
    if (!state.public_key) {
      return i18n.global.t('general.expiration.after')
    } else {
      let date = datetimeToString(state.public_key?.expiration)
      return `${i18n.global.t('general.expiration.before')} ${date}`
    }
  },
}
