<template>
  <div>
    <drag-drop-document
      :field_name="field_name"
      :filetype="filetype"
      @update="addDoc"
    >
      <document-link-input
        v-for="uuid in uuids"
        :key="uuid"
        :tagId="tagId"
        :label_name="label_name"
        :uuid="uuid"
        :field_name="field_name"
        :filetype="filetype"
        :use_comments="comments"
        :use_name_date="use_name_date"
        @update="addDoc"
        @delete="delDoc"
      ></document-link-input>

      <document-link-input
        :key="tagId"
        :label_name="label_name"
        :uuid="new_uuid"
        :field_name="field_name"
        :help_text="help_text"
        :tagId="tagId"
        :filetype="filetype"
        :use_name_date="use_name_date"
        @update="addDoc"
      ></document-link-input>
    </drag-drop-document>
  </div>
</template>

<script>
import DocumentLinkInput from '@/components/global/inputs/DocumentLinkInput.vue'
import DragDropDocument from '@/components/global/inputs/Drag&DropDocument.vue'

export default {
  name: 'DocumentsInput',

  components: {
    DocumentLinkInput,
    DragDropDocument,
  },

  props: {
    uuids: {
      type: Array,
      default: () => [],
    },
    field_name: {
      type: String,
      default: null,
    },
    filetype: {
      type: String,
      default: 'doc',
    },
    label_name: {
      type: String,
      default: null,
    },
    help_text: {
      type: String,
      default: null,
    },
    tagId: {
      type: String,
    },
    comments: {
      type: Boolean,
      default: false,
    },
    use_name_date: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      new_uuid: null,
    }
  },

  methods: {
    addDoc(field_name, value) {
      this.$emit('update', field_name, [...this.uuids, value])
      this.new_uuid = null
    },

    delDoc(field_name, value) {
      let new_uuids = [...this.uuids]
      new_uuids.splice(
        new_uuids.findIndex(el => el === value),
        1,
      )
      this.$emit('delete', field_name, new_uuids, value)
    },
  },
}
</script>
