import i18n from '@/locales'
import { api } from '@/main'
import _ from 'lodash'

export default {
  async getSecurityForms({ commit }, company_id) {
    // Получаем список СЗИ
    const result = await api.fetch({ company_id, security_id: null })
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('company.security.loading_error'), { root: true })
      return
    }
    // Загружаем в store
    commit('SET_SECURITY_FORMS', result.data)
  },

  async getSecurityForm({ commit }, payload) {
    // Получаем СЗИ
    const result = await api.fetch(payload)
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('company.security.loading_error'), { root: true })
      return
    }
    // Загружаем в store
    commit('SET_SECURITY_FORM', result.data)
  },

  async addSecurityForm({ commit }, payload) {
    // Добавляем СЗИ
    const result = await api.add({ company_id: payload.company, security_id: null, data: payload })
    if (result.status) {
      await commit('SUCCESS_TOAST', i18n.global.t('general.success.security_create'), { root: true })
      return result.data
    }
    // Если не удается создать СЗИ
    if (result.errors.length && !result.errors[0].response.data.freemium) {
      commit('DANGER_TOAST', i18n.global.t('company.security.creating_error'), { root: true })
    }
    // Превышен лимит анкет для демо компании
    else
      commit(
        'BANNER',
        {
          title: i18n.global.t('general.errors.quota.title'),
          message: i18n.global.t('general.errors.quota.message'),
          mail: i18n.global.t('general.errors.quota.mail'),
        },
        { root: true },
      )
  },

  async deleteSecurityForm({ commit }, payload) {
    // Удаляет СЗИ
    // Payload example: {company_id: 1, form_id: 1}
    const result = await api.delete({ company_id: payload.company_id, security_id: payload.form_id })
    if (result.status === 204) {
      commit('DELETE_SECURITY', payload.form_id)
    }
    return !result.errors.length
  },

  updateSecurityForm: _.debounce(async ({ dispatch, commit, getters }, payload) => {
    // Обновляем поле анкеты
    let data = {}
    data[payload.field_name] = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    commit('UPDATE_SECURITY_FORM', data)
    // В списке анкет проценты надо обновлять отдельно
    commit('UPDATE_PERCENT_IN_LIST', { form_id: payload.form.id, percent: getters.percent })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    data['percent'] = getters.percent
    const result = await api.update({
      company_id: payload.form.company,
      security_id: payload.form.id,
      data,
    })
    if (payload.field_name === 'name') {
      await dispatch('getSecurityForms', payload.form.company)
    }
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('company.security.editing_error'), { root: true })
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      dispatch('getSecurityForm', { company_id: payload.form.company, security_id: payload.form.id })
      return false
    }
    return true
  }, 500),

  async fetchCertificateFsb({ commit }) {
    const result = await api.fetch({ url: `/api/v2/certificates/fsb/` })
    if (result.errors.length) {
      return
    }
    // Загружаем в store
    commit('SET_CERTIFICATE_FSB', result.data)
  },

  async fetchCertificateFstek({ commit }) {
    const result = await api.fetch({ url: `/api/v2/certificates/fstek/` })
    if (result.errors.length) {
      return
    }
    // Загружаем в store
    commit('SET_CERTIFICATE_FSTEK', result.data)
  },
}
