import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,

  state: {
    network_hardwares_loaded: false,
    network_hardwares: [],
    network_hardware: {},
  },

  actions,
  mutations,
}
