<template>
  <div
    v-show="show"
    class="bmodal"
  >
    <transition
      name="fade"
      appear
    >
      <div
        v-if="show"
        class="bmodal__shadow"
        style=""
        @click="$emit('close', $event)"
      ></div>
    </transition>

    <transition
      name="fade"
      appear
    >
      <div
        v-show="show"
        :class="['bmodal__box', large ? 'large__box' : '']"
      >
        <div class="bmodal__box__title">
          <p>{{ title }}</p>

          <div class="icon">
            <img
              id="close_popup_modal"
              src="@/assets/img/close-icon.svg"
              @click="$emit('close', $event)"
            />
          </div>
        </div>

        <div class="bmodal__box__content">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/*
 * Бывший компонент от библиотеки bootstrap-vue, с новой версией vuejs перестал работать
 * поэтому переписан на свой компонент
 */

export default {
  name: 'b-modal-list',

  props: {
    title: String,
    show: Boolean,
    large: Boolean,
  },
}
</script>

<style lang="sass" scoped>

//   transition
.fade-enter-active, .fade-leave-active
  transition: opacity 0.3s

.fade-enter-from, .fade-leave-to
  opacity: 0

.bmodal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  z-index: 200
  display: flex
  align-items: center
  justify-content: center

  &__shadow
    background: rgba(0, 0, 0, 0.5)
    width: 100%
    height: 100vh
    z-index: -1
    position: absolute
    top: 0
    left: 0


  &__box
    border-radius: 10px
    width: 600px
    background: white
    padding: 30px
    max-height: 800px
    overflow-y: auto

    &__title
      display: flex
      justify-content: space-between
      align-items: center
      font-size: 22px
      font-weight: bold
      margin-bottom: 15px
      position: sticky
      top: -30px
      background-color: #FFFFFF
      padding-top: 20px

      .icon
        cursor: pointer

      p
        margin: 0

    &.large__box
      width: 1100px
</style>
