import i18n from '@/locales'
import { api } from '@/main'
import _ from 'lodash'
import { ProcessClient, checkApiResponse, ProcessSubjectClient } from '@/api/clients'

export default {
  // Process actions
  async fetchProcesses(context, payload) {
    const processClient = new ProcessClient(payload.company_id)
    const result = await processClient.fetchAll(payload.limit, payload.offset)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESSES', result.data)
    }
  },

  async orderingProcesses(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/processes/?offset=${payload.offset}&limit=${payload.limit}&ordering=${payload.ordering}`,
      context,
      {},
      'SET_PROCESSES',
      // i18n.global.t('company.processes.loading_processes_error'),
    )
  },

  async fetchAllProcessData(context, payload) {
    let result = await api.query(
      'GET',
      `/companies/${payload.company}/processes/${payload.process_id}/init_data/`,
      context,
      {},
      'SET_INIT_PROCESS_DATA',
    )
    await context.dispatch('company/fetchAllSystems', { company_id: payload.company }, { root: true })
    await context.dispatch('system/fetchSystems', { company_id: payload.company }, { root: true })
    await context.dispatch('company/fetchThirdParties', payload.company, { root: true })

    // if (!result) {
    //   await context.dispatch('fetchAllProcessData', payload)
    // }
  },

  async fetchProcess(context, payload) {
    // Получаем процесс
    const processClient = new ProcessClient(payload.company)
    const result = await processClient.fetch(payload.process_id)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESSES', result.data)
    }
  },

  async fetchDefaultProcesses(context, company_id) {
    // Получаем процессы по умолчанию
    await api.query(
      'GET',
      `/companies/${company_id}/processes/default_processes/`,
      context,
      {},
      'SET_DEFAULT_PROCESSES',
      // i18n.global.t('company.processes.loading_error'),
    )
  },

  async addProcess(context, payload) {
    // Добавляем процесс
    const result = await api.add({ url: `/api/v2/companies/${payload.company}/processes/`, data: payload })
    if (!result.errors.length) {
      context.commit('ADD_PROCESS', result.data)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_create'), { root: true })
      await context.dispatch('company/fetchCompanyTags', payload.company, { root: true })
      return result
    }
    // Если не удается создать процесс
    if (result.errors.length && !result.errors[0].response.data.freemium) {
      context.commit('DANGER_TOAST', i18n.global.t('company.processes.creating_error'), { root: true })
    }
    // Превышен лимит анкет для демо компании
    else
      context.commit(
        'BANNER',
        {
          title: i18n.global.t('general.errors.quota.title'),
          message: i18n.global.t('general.errors.quota.message'),
          mail: i18n.global.t('general.errors.quota.mail'),
        },
        { root: true },
      )
  },

  async copyProcess(context, payload) {
    // Копирование процесса
    const result = await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/copy/`,
      context,
      payload,
      'ADD_PROCESS',
      i18n.global.t('company.processes.creating_error'),
    )
    if (result.status) {
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_create'), { root: true })
    }
    // Обновляем анкету Отделы и должности если копируем данные ИСПДн, используемые в рамках копируемого процесса
    if (payload.is_copy_systems) {
      await context.dispatch('company/fetchDepartment', payload.company, { root: true })
    }
    return result
  },

  async deleteProcess(context, payload) {
    // Удаляет процесс
    const processClient = new ProcessClient(payload.company)
    const result = await processClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS', payload.id ? payload.id : payload.uuid)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_delete'), { root: true })
    }
  },

  updateProcess: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    if (context.rootState.company?.public_key?.comments_only) {
      return
    }
    let fields = {}
    fields[payload.field_name] = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_PROCESS', { fields, getters: context.getters })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    const processClient = new ProcessClient(payload.form.company)
    const result = await processClient.update(
      payload.form.id,
      { ...fields, percent: context.getters.percent },
      !!context.state.user,
    )
    await checkApiResponse(result, context)
    if (result.error) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchProcess', { company: payload.form.company, process_id: payload.form.id })
    }
  }, 500),

  async deleteProcessDocument(context, payload) {
    // Обновляем поле анкеты
    if (context.rootState.company?.public_key?.comments_only) {
      return
    }
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    context.commit('UPDATE_PROCESS', { fields, getters: context.getters })
    const processClient = new ProcessClient(payload.form.company)
    const result = await processClient.update(payload.form.id, { ...fields, percent: context.getters.percent })
    await checkApiResponse(result, context)
    if (!result.error) {
      //Удаляем документ, если смогли отвязать от процесса
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async updateProcessName(context, payload) {
    context.commit('SET_PROCESS', payload.form)
    await context.dispatch('updateProcess', payload)
  },

  // Subject
  async fetchProcessSubjects(context, payload) {
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.process_id)
    const result = await processSubjectClient.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_SUBJECTS', result.data)
    }
  },

  async addSubject(context, payload) {
    // Добавляем субъекта
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.process)
    const result = await processSubjectClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_SUBJECT', result.data)
    }
    await context.dispatch('company/fetchSubjects', payload.company, { root: true })
  },

  async deleteSubject(context, payload) {
    // Удаляет процесс
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.process)
    const result = await processSubjectClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_SUBJECT', payload.id)
    }
    await context.dispatch('company/fetchSubjects', payload.company, { root: true })
    payload = { ...payload, process_id: payload.process }
    await context.dispatch('fetchSourceSubjects', payload)
    await context.dispatch('fetchProcessSystemSubjects', payload)
    await context.dispatch('fetchProcessThirdPartySubjects', payload)
  },

  updateSubject: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    let fields = {}
    fields[payload.field_name] = payload.value
    fields['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.form.process)
    const result = await processSubjectClient.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', fields)
    }
  }, 500),

  async deleteSubjectDocument(context, payload) {
    let uuids = {}
    uuids[payload.field_name] = payload.new_uuids
    uuids['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.form.process)
    const result = await processSubjectClient.update(payload.form.id, uuids)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', uuids)
    }
    if (!result.error) {
      //Удаляем документ, если смогли отвязать от субъекта
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async updateSubjectFields(context, payload) {
    // Обновляем поля действий в анкете
    let fields = {}
    fields = payload.value
    fields['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.form.process)
    const result = await processSubjectClient.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', fields)
    }
  },

  // Position actions
  async addPosition(context, payload) {
    // Добавляем отдел
    await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/positions/`,
      context,
      payload,
      'ADD_POSITION',
    )
  },

  async deletePosition(context, payload) {
    // Удаляет процесс
    await api.query(
      'DELETE',
      `/companies/${payload.company}/processes/${payload.process}/positions/${payload.id}/`,
      context,
      payload,
      'DELETE_POSITION',
    )
  },

  // Субъекты привязанные к источникам
  async fetchSourceSubjects(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company}/processes/${payload.process_id}/source_subjects/`,
      context,
      {},
      'SET_SOURCE_SUBJECTS',
    )
  },

  async addSourceSubject(context, payload) {
    // Добавляем субъект источника
    await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/source_subjects/`,
      context,
      payload,
      'ADD_SOURCE_SUBJECT',
    )
  },

  async deleteSourceSubject(context, payload) {
    // Удаляет процесс
    await api.query(
      'DELETE',
      `/companies/${payload.company}/processes/${payload.process}/source_subjects/${payload.id}/`,
      context,
      payload,
      'DELETE_SOURCE_SUBJECT',
    )
  },

  updateSourceSubject: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    let data = {}
    data[payload.field_name] = payload.value
    await api.query(
      'UPDATE',
      `/companies/${payload.company}/processes/${payload.process}/source_subjects/${payload.form.id}/`,
      context,
      data,
      'UPDATE_SOURCE_SUBJECT',
      i18n.global.t('company.processes.editing_error'),
      '',
    )
    await context.dispatch('fetchProcessSubjects', { company: payload.company, process_id: payload.process })
  }, 100),

  // Публичные источники привязанные процессу
  async addPublicSource(context, payload) {
    return await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/public_sources/`,
      context,
      payload,
      'ADD_PUBLIC_SOURCE',
    )
  },

  async deletePublicSource(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/processes/${payload.process}/public_sources/${payload.id}/`,
      context,
      payload,
      'DELETE_PUBLIC_SOURCE',
    )
  },

  // Другие источники привязанные процессу
  async addOtherSource(context, payload) {
    return await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/other_sources/`,
      context,
      payload,
      'ADD_OTHER_SOURCE',
    )
  },

  async deleteOtherSource(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/processes/${payload.process}/other_sources/${payload.id}/`,
      context,
      payload,
      'DELETE_OTHER_SOURCE',
    )
  },

  // Бумажные документы привязанные процессу
  async fetchPaperDocs(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company}/processes/${payload.process_id}/paper_docs/`,
      context,
      {},
      'SET_PAPER_DOCS',
    )
  },

  async addPaperDocs(context, payload) {
    let result = await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/paper_docs/`,
      context,
      payload,
      'ADD_PAPER_DOCS',
    )
    if (result) {
      return result
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.processes.error_name'), { root: true })
    }
  },

  async deletePaperDocs(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/processes/${payload.process}/paper_docs/${payload.id}/`,
      context,
      payload,
      'DELETE_PAPER_DOC',
    )
  },

  async updatePaperDoc(context, payload) {
    // Обновляем документ
    let data = {}
    data[payload.field_name] = payload.value

    await api.query(
      'UPDATE',
      `/companies/${payload.company}/processes/${payload.process}/paper_docs/${payload.form.id}/`,
      context,
      data,
      'UPDATE_PAPER_DOC',
      '',
    )
  },

  // Системы привязанные процессу
  async fetchProcessSystems(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company}/processes/${payload.process_id}/process_systems/`,
      context,
      {},
      'SET_PROCESS_SYSTEMS',
    )
  },

  async addProcessSystem(context, payload) {
    return await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/process_systems/`,
      context,
      payload,
      'ADD_PROCESS_SYSTEM',
    )
  },

  async deleteProcessSystem(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/processes/${payload.process}/process_systems/${payload.id}/`,
      context,
      payload,
      'DELETE_PROCESS_SYSTEM',
    )
  },

  async updateProcessSystem(context, payload) {
    // Обновляем систему
    let data = {}
    data[payload.field_name] = payload.value

    await api.query(
      'UPDATE',
      `/companies/${payload.company}/processes/${payload.process}/process_systems/${payload.form.id}/`,
      context,
      data,
      'UPDATE_PROCESS_SYSTEM',
      '',
    )
    if (payload.field_name === 'positions') {
      await context.dispatch('company/fetchDepartment', payload.company, { root: true })
    }
  },

  // Субъекты системы привязанной к процессу
  async fetchProcessSystemSubjects(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company}/processes/${payload.process_id}/process_system_subjects/`,
      context,
      {},
      'SET_PROCESS_SYSTEM_SUBJECTS',
    )
  },

  async addProcessSystemSubject(context, payload) {
    return await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/process_system_subjects/`,
      context,
      payload,
      'ADD_PROCESS_SYSTEM_SUBJECT',
    )
  },

  async deleteProcessSystemSubject(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/processes/${payload.process}/process_system_subjects/${payload.id}/`,
      context,
      payload,
      'DELETE_PROCESS_SYSTEM_SUBJECT',
    )
  },

  updateProcessSystemSubject: _.debounce(async (context, payload) => {
    // Обновляем систему
    let data = {
      process_system: payload.process_system,
      subject: payload.subject,
    }
    data[payload.field_name] = payload.value

    await api.query(
      'UPDATE',
      `/companies/${payload.company}/processes/${payload.process}/process_system_subjects/${payload.form.id}/`,
      context,
      data,
      'UPDATE_PROCESS_SYSTEM_SUBJECT',
      '',
    )
    await context.dispatch('fetchProcessSubjects', { company: payload.company, process_id: payload.process })
  }, 100),

  async addProcessThirdParty(context, payload) {
    return await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/process_third_parties/`,
      context,
      payload,
      'ADD_PROCESS_THIRD_PARTY',
    )
  },

  async deleteProcessThirdParty(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/processes/${payload.process}/process_third_parties/${payload.id}/`,
      context,
      payload,
      'DELETE_PROCESS_THIRD_PARTY',
    )
  },

  updateProcessThirdParty: _.debounce(async (context, payload) => {
    // Обновляем систему
    let data = {}
    data[payload.field_name] = payload.value
    context.commit('UPDATE_PROCESS_THIRD_PARTY', { ...data, id: payload.form.id })

    await api.query(
      'UPDATE',
      `/companies/${payload.company}/processes/${payload.process}/process_third_parties/${payload.form.id}/`,
      context,
      data,
      null,
      '',
    )
  }, 50),

  async deleteDocumentThirdParty(context, payload) {
    let uuids = {}
    uuids[payload.field_name] = payload.new_uuids
    context.commit('UPDATE_PROCESS_THIRD_PARTY', { ...uuids, id: payload.form.id })

    const result = await api.query(
      'UPDATE',
      `/companies/${payload.company}/processes/${payload.process}/process_third_parties/${payload.form.id}/`,
      context,
      uuids,
      null,
      '',
    )
    if (!result.error) {
      //Удаляем документ, если смогли отвязать
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async fetchProcessThirdPartySubjects(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company}/processes/${payload.process_id}/process_third_party_subjects/`,
      context,
      {},
      'SET_PROCESS_THIRD_PARTY_SUBJECTS',
    )
  },

  async addProcessThirdPartySubject(context, payload) {
    return await api.query(
      'CREATE',
      `/companies/${payload.process.company}/processes/${payload.process.id}/process_third_party_subjects/`,
      context,
      payload,
      'ADD_PROCESS_THIRD_PARTY_SUBJECT',
    )
  },

  async deleteProcessThirdPartySubject(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.process.company}/processes/${payload.process.id}/process_third_party_subjects/${payload.id}/`,
      context,
      payload,
      'DELETE_PROCESS_THIRD_PARTY_SUBJECT',
    )
  },

  updateProcessThirdPartySubject: _.debounce(async (context, payload) => {
    // Обновляем систему
    let data = {}
    data[payload.field_name] = payload.value

    await api.query(
      'UPDATE',
      `/companies/${payload.process.company}/processes/${payload.process.id}/process_third_party_subjects/${payload.form.id}/`,
      context,
      data,
      'UPDATE_PROCESS_THIRD_PARTY_SUBJECT',
      '',
    )
    await context.dispatch('fetchProcessSubjects', { company: payload.process.company, process_id: payload.process.id })
  }, 100),

  async searchProcesses(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/processes/?search=${payload.search}`,
      context,
      {},
      'FILTER_PROCESSES',
      // i18n.global.t('company.processes.loading_processes_error'),
    )
  },

  async filteredTagsProcesses(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/processes/?tags=${payload.tags}&limit=${payload.limit}`,
      context,
      {},
      'SET_PROCESSES',
      // i18n.global.t('company.processes.loading_processes_error'),
    )
  },

  // Запрашиваем неактивные процессы
  async fetchInactiveProcesses(context, payload) {
    await api.query('GET', `/companies/${payload.company_id}/processes/inactive`, context, {}, 'SET_INACTIVE_PROCESSES')
  },

  //Переместить процесс в активные
  async moveProcessToActive(context, payload) {
    await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/inactive/${payload.id}/`,
      context,
      payload,
      '',
      // 'UPDATE_PROCESS',
      i18n.global.t('company.inactive_processes.move_error'),
      i18n.global.t('company.inactive_processes.move_active'),
    )
    await context.dispatch('fetchProcesses', { company_id: payload.company, offset: 0, limit: 100 })
    await context.dispatch('fetchInactiveProcesses', { company_id: payload.company })
  },

  async moveProcessToInactive(context, payload) {
    //Переместить процесс в неактивные
    await api.query(
      'UPDATE',
      `/companies/${payload.company}/processes/${payload.id}/`,
      context,
      payload,
      '',
      // 'UPDATE_PROCESS',
      i18n.global.t('company.inactive_processes.move_error'),
      i18n.global.t('company.inactive_processes.move_active'),
    )
    await context.dispatch('fetchProcesses', { company_id: payload.company, offset: 0, limit: 100 })
    await context.dispatch('fetchInactiveProcesses', { company_id: payload.company })
  },
}
