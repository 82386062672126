const EMPTY_ME = {
  id: null,
  username: '',
  status: null,
  is_superuser: false,
  is_analyst: false,
  is_client: false,
}

export default class UserService {
  constructor(payload) {
    this.server = payload.server
    this.client = payload.client
    this.me = EMPTY_ME
    this.accessToken = localStorage.getItem('accessToken')
    this.refreshToken = localStorage.getItem('refreshToken')
    this.privacyHeader = null
  }
}
