export default {
  SET_COMPANIES(state, companies) {
    state.companies = companies
    state.companies_loaded = true
  },
  SET_DETAILS(state, detail) {
    state.detail = detail
  },

  CHANGE_SELECTED_BLOCK(state, num) {
    state.selected_dashboard_block = num
    state.selected_directory_block = num
  },

  CHANGE_DASHBOARD_LOADING(state, status) {
    state.dashboard_loading = status
  },
}
