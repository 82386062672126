<template>
  <section class="input_section">
    <p
      v-if="!no_name"
      :id="`select_input_name_${unique_id}`"
      class="input_name"
    >
      {{ field_name }}
      <span
        v-if="required"
        class="red"
        >*</span
      >
    </p>

    <div
      v-click-outside="closeSelect"
      class="custom_select"
    >
      <div
        :id="`show_fold_menu_${unique_id}`"
        :class="['parent_block', color && modelValue === 'published' ? 'selected_parent_block' : '']"
        @click="show_fold_menu = true"
      >
        <div class="text">{{ getFieldValue() }}</div>
        <div
          v-if="show_fold_menu"
          class="icon"
        >
          <img src="@/assets/img/arrow-down-gray.svg" />
        </div>
        <div
          v-if="!show_fold_menu"
          class="icon"
        >
          <img src="@/assets/img/arrow-up-gray.svg" />
        </div>
      </div>

      <div
        v-if="show_fold_menu"
        :id="`select_input_fold_block_${unique_id}`"
        class="fold_block"
      >
        <div
          v-for="(option, index) in options"
          :id="`select_input_values_${unique_id}`"
          :key="index"
          :class="['block', isValueEqualField(option) ? 'selected' : '']"
          @click="selectValue(option)"
        >
          <div class="text">{{ typeof option === 'object' ? option.name : option }}</div>
          <img
            v-if="is_hints && option.hint"
            :id="`select_input_hint_${unique_id}`"
            class="list_hint"
            src="@/assets/img/question.svg"
            :title="option.hint"
          />
          <div class="icon">
            <img
              v-if="isValueEqualField(option)"
              src="@/assets/img/check-icon.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
Первый вариант.
У нас есть список значений array с string и мы должны выбрать один. Отображаться будет значение
<select-input v-model="user.status"
        :options="['analyst', 'client']"
        field_name="Роль пользователя"></select-input>

Второй вариант
У нас есть Array только вместо строк дикты, в которых обязательно должны быть поля name - будет отображаться,
и value, который будет в v-model улетать
<select-input v-model="user.status"
        :options="[{name: 'Аналитик', value: 'analyst'}, {name: 'Аналитик', value: 'analyst'}]"
        field_name="Роль пользователя"></select-input>
*/
export default {
  name: 'select-input',

  props: {
    modelValue: [String, Number],
    field_name: String,
    options: Array,
    no_name: Boolean,
    default_element: Number,
    required: {
      type: Boolean,
      default: false,
    },
    color: {
      type: Boolean,
      default: false,
    },
    is_hints: {
      type: Boolean,
      default: false,
    },
    tagId: String,
  },

  data() {
    return {
      unique_id: '',
      show_fold_menu: false,
      selected_field: this.modelValue,
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  watch: {
    selected_field: {
      handler() {
        this.$emit('update:modelValue', this.selected_field)
      },
    },
  },

  mounted() {
    this.default_element ? this.$emit('update:modelValue', this.default_element) : ''
  },

  methods: {
    closeSelect() {
      this.show_fold_menu = false
    },

    getFieldValue() {
      for (let i = 0; i < this.options.length; i++) {
        if (typeof this.options[i] === 'object') {
          if (this.options[i].value === this.modelValue) {
            return this.options[i].name
          }
        } else {
          if (this.options[i] === this.modelValue) {
            return this.options[i]
          }
        }
      }
    },

    isValueEqualField(value) {
      /* Need to check type first, if it just string - need to check */
      if (typeof value === 'object') {
        if (String(value.value) === String(this.modelValue)) {
          return true
        }
      } else {
        if (value === this.modelValue) {
          return true
        }
      }
      return false
    },

    selectValue(option) {
      if (typeof option === 'object') {
        this.$emit('update:modelValue', option.value)
      } else {
        this.$emit('update:modelValue', option)
      }
      this.closeSelect()
    },
  },
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>
