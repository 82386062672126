<template>
  <section class="input_section">
    <div>
      <div class="taginput_name">
        <p class="input_name">{{ field_name ? field_name : '' }}</p>

        <button
          v-if="clean_field"
          class="taginput_clean"
          @click="cleanField()"
        >
          Очистить поле
        </button>
        <button
          v-if="clean_field"
          class="taginput_clean"
          @click="fromParent()"
        >
          Загрузить
        </button>
      </div>

      <div
        v-click-outside="closeData"
        class="fold_text_input"
      >
        <div class="add_input">
          <form @submit.prevent="addElementManual(new_element ? new_element : new_element_name)">
            <div :class="['data_wrapper', 'exist', { active_scroll: !!public_key?.comments_only }]">
              <div
                v-for="(elem, index) in updated_exist_elements"
                :id="`exist_object_${index + 1}`"
                :key="elem.id"
                :class="['data_el', { 'disabled-block': !!public_key?.comments_only }]"
              >
                {{ label_param ? elem[label_param] : elem }}
                <img
                  src="@/assets/img/close-icon.svg"
                  @click="deleteElementOrModal(index)"
                />
              </div>

              <!-- input if exist_elements === [{id: 1, name: 'Vasya'}] -->
              <input
                v-if="typeof exist_elements === 'object'"
                :id="`default_elements_${unique_id}`"
                ref="input"
                v-model="new_element[label_param]"
                :placeholder="placeholder"
                @input="updateFilteredDefaultElements()"
                @focus="openFoldBox()"
              />

              <!-- input if exist_elements === "Vasya, Petya" -->
              <input
                v-if="typeof exist_elements === 'string'"
                :id="`default_elements_${unique_id}`"
                ref="input"
                v-model="new_element_name"
                :placeholder="placeholder"
                @input="updateFilteredDefaultElements()"
                @focus="openFoldBox()"
              />
            </div>
          </form>
        </div>

        <div
          v-if="show_fold_data"
          class="fold"
        >
          <div
            v-if="filtered_default_elements.length"
            :class="['data_wrapper', 'exist', { active_scroll: !!public_key?.comments_only }]"
          >
            <div
              v-for="(element, index) in filtered_default_elements"
              :id="`default_object_${index + 1}`"
              :key="index"
              :class="['data_el', { 'disabled-block': !!public_key?.comments_only }]"
              @click="addElement(element)"
            >
              {{ label_param ? element[label_param] : element }}
            </div>
          </div>
          <div class="absent_el">
            <p>{{ not_set_values }}</p>
          </div>

          <!-- этот блок показывается, если нет значений и нельзя добавлять руками-->
          <div
            v-if="!filtered_default_elements.length && no_manual"
            class="absent_el"
          >
            <img src="@/assets/img/fire-icon.svg" />
            <p>{{ $t('forms.process.5.per.not_values_def') }}</p>
          </div>

          <div
            v-if="!no_manual"
            class="new_field_wrapper"
          >
            <div class="data_wrapper">
              <span
                v-if="typeof exist_elements === 'object'"
                style="margin-right: 15px"
              >
                {{ new_element[label_param].length ? $t('general.create') : $t('general.typing') }}
              </span>

              <span
                v-if="typeof exist_elements === 'string'"
                style="margin-right: 15px"
              >
                {{ new_element_name.length ? $t('general.create') : $t('general.typing') }}
              </span>

              <div
                v-if="typeof exist_elements === 'object' && new_element[label_param].length"
                class="data_el binded yellow"
              >
                {{ new_element[label_param] }}
              </div>
              <div
                v-if="typeof exist_elements === 'string' && new_element_length"
                class="data_el binded yellow"
              >
                {{ new_element_name }}
              </div>
            </div>
            <div
              id="add_elem_btn"
              class="press_btn"
              @click="addElement(new_element ? new_element : new_element_name)"
            >
              <p>{{ $t('general.press') }}</p>
              <img src="@/assets/img/icon-keyboard-enter.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <delete-modal
      :show="show_delete_modal"
      :title="$t('general.modal.delete.common.title')"
      :desc="$t('general.modal.delete.common.desc')"
      @close="show_delete_modal = false"
      @approve="deleteElement(current_index)"
    ></delete-modal>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import axios from 'axios'
/*
Send request to api to create new element

<tag-input field_name="Название субъекта"
           :exist_elements="[{name: 'Subject', id: 1}]"
           :default_elements="[{name: 'Василий', process: 1}]"
           label_param='name'
           @update="process.subjects = $event"
           placeholder="Ввведите название субъекта"
           url="subject_source"
           :new_element="{process: 2, name: '', subject: 3}"></tag-input>


Return and work with string

<tag-input :field_name="$t('forms.process.5.spec.name')"
           :exist_elements="subject.special_data"
           :default_elements="['список', 'значений']"
           :placeholder="$t('forms.process.5.spec.placeholder')"
           @update="subject.special_data = $event"></tag-input>
*/
export default {
  name: 'tag-input',

  props: {
    // first variant params
    field_name: String,
    exist_elements: {
      type: [Array, String],
    }, // can be array or string
    default_elements: {
      type: Array,
      default: () => [],
    },
    label_param: String, // default ставить нельзя, потому что все сломается
    placeholder: String,
    url: String,
    new_element: {
      type: Object,
      default: () => {},
    },

    // second variant params
    // same as first variant

    // utility params
    url_delete_additional_arg: String,
    url_add_additional_arg: String,
    delete_param: {
      type: String,
      default: 'id',
    },
    // before deleting ask you in modal window
    approve: {
      type: Boolean,
      default: false,
    },

    // * может понадобиться запрет на добавление собственных значений, то есть поле будет использоваться только для
    // * добавлений готовых значений, а руками вписывать ничего нельзя
    // * тогда нам нужно использовать input только для поиска и убрать блок (Начните вводить данные...)
    no_manual: {
      type: Boolean,
      default: false,
    },

    // * если не заданы значения, передаем текст
    not_set_values: String,

    // * если поле текстовое - можно добавить кнопку "Отчистить поле", она просто вернет в родительский
    // * компонент пустую строку
    clean_field: {
      type: Boolean,
      default: false,
    },

    payload: {
      default: {},
    },
    tagId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      show_fold_data: false,

      new_element_name: '',

      show_delete_modal: false,
      current_index: 0,

      filtered_default_elements: [],
      unique_id: '',
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    ...mapState(['server', 'system']),
    ...mapState('company', ['public_key']),

    new_element_length() {
      if (typeof this.exist_elements === 'object') {
        return this.new_element[this.label_param].length
      } else {
        return this.new_element_name.length
      }
    },

    updated_exist_elements() {
      if (typeof this.exist_elements === 'object') {
        return this.exist_elements
      } else {
        if (this.exist_elements?.length) {
          return this.exist_elements.split(/\s*;\s*/).filter(el => el.length)
        }
      }
      return []
    },

    exclude_default_elements() {
      /*
      Из списка default_elements удаляются все элементы, добавленные в exist_elements
      */
      if (typeof this.exist_elements === 'object') {
        let exist_elements_names = this.exist_elements.map(elem =>
          this.label_param ? elem[this.label_param] : elem.name,
        )

        return this.default_elements.filter(elem => {
          return !exist_elements_names.includes(this.label_param ? elem[this.label_param] : elem.name)
        })
      } else if (typeof this.exist_elements === 'string') {
        let list_exist_elements = this.exist_elements.split(/\s*;\s*/)
        return this.default_elements.filter(elem => {
          return !list_exist_elements.includes(elem)
        })
      }
      return []
    },
  },

  mounted() {
    this.updateFilteredDefaultElements()
  },

  methods: {
    ...mapMutations(['DANGER_TOAST']),

    openFoldBox() {
      this.show_fold_data = true
      this.updateFilteredDefaultElements()
    },

    focusOnInput() {
      setTimeout(() => {
        this.$refs.input.focus()
        this.$refs.input.scrollTop = 9999
      }, 0)
    },

    addElementManual(value) {
      if (!this.no_manual) {
        this.addElement(value)
      } else {
        this.DANGER_TOAST('В данном поле вы можете добавить только из предложенных значений')
      }
    },

    cleanField() {
      if (typeof this.exist_elements === 'string') {
        this.$emit('update', '')
      } else {
        throw TypeError('Error: In tag-input component you can use only string value for updates.')
      }
    },

    fromParent() {
      axios
        .get(`${this.server}/api/v1/anon_information_system/get_subjects/`, {
          params: {
            secret_url: this.system.secret_url,
            object_id: this.payload?.object.id,
            object_type: this.payload?.object.type,
            name: this.field_name,
          },
          headers: this.$store.state.auth_headers,
        })
        .then(response => {
          this.$emit('update', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },

    updateFilteredDefaultElements() {
      /*
      Событие висит на инпуте, пересчитывает список после каждого нажатия
      */
      if (typeof this.exist_elements === 'object') {
        this.filtered_default_elements = this.exclude_default_elements.filter(elem => {
          return elem[this.label_param].toLowerCase().includes(this.new_element[this.label_param].toLowerCase())
        })
      } else if (typeof this.exist_elements === 'string') {
        this.filtered_default_elements = this.exclude_default_elements.filter(elem =>
          elem.toLowerCase().includes(this.new_element_name.toLowerCase()),
        )
      }
    },

    addElement(object) {
      if (typeof this.exist_elements === 'object') {
        if (object[this.label_param]?.replace(/\s+/g, '').length) {
          this.new_element_name = ''
          this.focusOnInput()
        }
      } else {
        if (object.length) {
          this.new_element_name = ''
          this.$emit('update', this.exist_elements + this.checkElementDivider() + object + ' ')
          this.focusOnInput()
        }
      }
    },

    checkElementDivider() {
      // in tag-input last element must be ';', in other case string will be union with another string
      // if string is not blank we need check last or pre-last element and it have not ';' - return it
      if (this.exist_elements.length > 1) {
        if (
          this.exist_elements[this.exist_elements.length - 1] !== ';' &&
          this.exist_elements[this.exist_elements.length - 2] !== ';'
        ) {
          return '; '
        }
      }
      return ''
    },

    deleteElementOrModal(index) {
      if (this.approve) {
        this.show_delete_modal = true
        this.current_index = index
      } else {
        this.deleteElement(index)
      }
    },

    deleteElement(index) {
      if (typeof this.exist_elements === 'object') {
        let object = this.exist_elements[index]
        this.$axios
          .delete(
            `${this.server}/api/v1/${this.url}/${object[this.delete_param]}/${this.url_delete_additional_arg ? this.url_delete_additional_arg + '/' : ''}`,
          )
          .then(() => {
            let new_list = [...this.exist_elements]
            new_list.splice(index, 1)
            this.$emit('update', new_list)
          })
          .catch(error => {
            console.log(error)
            this.DANGER_TOAST('Ошибка во время удаления объекта')
          })
      } else {
        let new_list = [...this.updated_exist_elements]
        new_list.splice(index, 1)
        this.$emit('update', new_list.join('; ') + '; ')
      }
      // close delete-modal
      if (this.approve) {
        this.show_delete_modal = false
      }
    },

    closeData() {
      this.show_fold_data = false
    },
  },
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>
