import i18n from '@/locales'
import { api } from '@/main'
import _, { throttle } from 'lodash'

export default {
  async fetchWorkstations({ commit }, company_id) {
    // Получаем список серверов
    const result = await api.fetch({ company_id, workstation_id: null })
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('general.workstation.loading_error'), { root: true })
      return
    }
    // Загружаем в store
    commit('SET_WORKSTATIONS', result.data)
  },

  async getWorkstation({ commit }, payload) {
    const result = await api.fetch(payload)
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('general.workstation.loading_error'), { root: true })
      return
    }
    // Загружаем в store
    commit('SET_WORKSTATION', result.data)
  },

  async addWorkstation(context, payload) {
    const result = await api.add({ company_id: payload.company, workstation_id: null, data: payload })
    if (result.status) {
      context.commit('ADD_WORKSTATION', result.data)
      await context.commit('SUCCESS_TOAST', i18n.global.t('general.success.workstation_create'), { root: true })
      return
    }
    // Если не удается создать АРМ
    if (result.errors.length && !result.errors[0].response.data.freemium) {
      context.commit('DANGER_TOAST', i18n.global.t('company.workstation.creating_error'), { root: true })
    }
    // Превышен лимит анкет для демо компании
    else
      context.commit(
        'BANNER',
        {
          title: i18n.global.t('general.errors.quota.title'),
          message: i18n.global.t('general.errors.quota.message'),
          mail: i18n.global.t('general.errors.quota.mail'),
        },
        { root: true },
      )
  },

  async deleteWorkstation(context, payload) {
    const result = await api.delete({ company_id: payload.company_id, workstation_id: payload.form_id })
    if (result.status === 204) {
      context.commit('DELETE_WORKSTATION', payload.form_id)
    }
    if (result.status) {
      await context.commit('SUCCESS_TOAST', i18n.global.t('general.success.workstation_delete'), { root: true })
    }
    return !result.errors.length
  },

  updateWorkstation: _.debounce(async ({ dispatch, commit }, payload) => {
    // Обновляем поле анкеты
    let data = {}
    data[payload.field_name] = payload.value

    const result = await api.update({
      company_id: payload.form.company,
      workstation_id: payload.form.id,
      data,
    })
    if (payload.field_name === 'name') {
      await dispatch('fetchWorkstations', payload.form.company)
    }
    commit('UPDATE_WORKSTATION', data)
    return true
  }, 500),

  async fetchAllDataWorkstation({ commit }, payload) {
    const result = await api.fetch({
      url: `/api/v2/companies/${payload.company}/workstations/${payload.id}/init_data/`,
    })
    if (result.errors.length) {
      return
    }
    // Загружаем в store
    commit('SET_WORKSTATION', result.data)
  },

  async addEmployee({ commit }, payload) {
    let data = {}
    data[payload.field_name] = payload.value
    const result = await api.add({
      url: `/api/v2/companies/${payload.company}/workstations/${payload.w_id}/employee_units/`,
      data,
    })
    if (result.errors.length) {
      return
    }
    // Загружаем в store
    commit('ADD_EMPLOYEE_UNIT', result.data)
  },

  updateEmployee: _.debounce(async (context, payload) => {
    // Обновляем Сотрудников Общества
    let data = {}
    data[payload.field_name] = payload.value

    await api.query(
      'UPDATE',
      `/companies/${payload.company}/workstations/${payload.w_id}/employee_units/${payload.unit_id}/`,
      context,
      data,
      'UPDATE_EMPLOYEE_UNIT',
      '',
    )
  }, 1000),

  async addThirdParty({ commit }, payload) {
    let data = {}
    data[payload.field_name] = payload.value
    const result = await api.add({
      url: `/api/v2/companies/${payload.company}/workstations/${payload.w_id}/third_party_units/`,
      data,
    })
    if (result.errors.length) {
      return
    }
    // Загружаем в store
    commit('ADD_THIRD_PARTY_UNIT', result.data)
  },

  updateThirdParty: _.debounce(async (context, payload) => {
    // Обновление Третьих лиц
    let data = {}
    data[payload.field_name] = payload.value

    await api.query(
      'UPDATE',
      `/companies/${payload.company}/workstations/${payload.w_id}/third_party_units/${payload.unit_id}/`,
      context,
      data,
      'UPDATE_THIRD_PARTY_UNIT',
      '',
    )
  }, 1000),
}
