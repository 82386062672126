export default {}

function countBlockFieldsRecursive(obj, field, req_len, count, need_result = 'int') {
  /*
   * цель - увеличить requirement_fields и count, либо проверить заполнено ли все и вернуть true/false
   * первое увеличивается просто по количеству полей, второе, после того, как посчитается isFieldFilled
   * field может быть вида
   * 1. {name: 'docs', filter: {field: 'name', value: 'специальные'}} - в таком случае мы проверяем есть ли в obj
   *    такой объект с условиями фильтрации
   * 2. {name: 'is_writen_agreement', value: 'собирается', block_fields: ['any', 'field']} - в таком случае мы проверяем что
   *    field == value и проверяем заполненность block_fields через функцию isFieldFilled */
  if (need_result === 'int') {
    if (isFieldFilledInObject(obj, field)) {
      if (obj[field.name] === field.value) {
        req_len += field.block_fields.length

        for (let i = 0; i < field.block_fields.length; i++) {
          let block_field = field.block_fields[i]

          if (typeof block_field === 'string') {
            if (isFieldFilled(obj, block_field)) {
              count++
            }
          } else {
            let result = countBlockFieldsRecursive(obj, block_field, req_len, count)
            req_len = result[0]
            count = result[1]
          }
        }
      }
      // при любых данных Заполняется, будь то обрабатывается или не обрабатывается
      count++
    }
    return [req_len, count]
  } else {
    // этот блок нужен, чтобы посчитать, все ли поля заполнены и вернуть true или false
    // console.log('field', field, need_result, obj)
    if (isFieldFilledInObject(obj, field)) {
      if (obj[field.name] === field.value) {
        return field.block_fields.every(block_field => {
          if (typeof block_field === 'string') {
            return isFieldFilled(obj, block_field)
          } else {
            return countBlockFieldsRecursive(obj, block_field, 0, 0, 'bool')
          }
        })
      }
      return true
    }
  }
}

function isFieldFilledInObject(obj, field) {
  /* Данная функция проверяет, заполнено ли поле в объекте, сложность в том
  что поле может быть просто 'is_agreement' - тем самым оно проверяет obj[field.name]
  либо {name: 'docs', filter: {field: 'name', value: 'специальные'}}*/
  // console.log(field, obj)
  if (field.filter) {
    return !!obj[field.name].filter(element => element[field.filter.field] === field.filter.value)?.length
  } else {
    // * если значение стоит в чекбоксе, то необходимо возвращать в любом случае true, потому что
    // * оно будет bool
    if (typeof obj[field.name] === 'boolean') {
      return true
    } else {
      return !!obj[field.name]?.length
    }
  }
}

function getFormSectionStatus(form, fields, required = true) {
  // * checking status of form section counting fields
  // * :@param required: if section have only optional fields -> required should be false
  // * return text link in rus or eng dictionary

  if (Object.keys(form).length) {
    if (fields.every(field => checkFieldStatus(form, field))) {
      return 'general.statuses.filled'
    } else if (fields.some(field => checkFieldStatus(form, field))) {
      return 'general.statuses.filling'
    } else {
      return required ? 'general.statuses.not_filled' : 'general.statuses.no_data'
    }
  }
}

function checkFieldStatus(form, field) {
  // * Главная цель, проверять поля внутри объекта, пока они не закончатся
  // * когда первый раз подается на вход form - он является объектом первого уровня, то есть процессом или системой
  // * Вместе с form подается на вход поле, которое нужно проверить, если поле является строкой,
  // * то это уже дело isFieldFilled функции
  // * если мы столкнулись с object, то это поле в форме может быть списком с объектами,
  // * где нужно проверить внутренние поля.
  // * если это так, мы начинаем пробегаться по этим полям и если мы опять натыкаемся на тип object, то вызываем
  // * эту функцию рекурсивно, только form уже является внутренний объект (например subject), а field - поле внутри subject. Например: 'process_list'
  // * если объект не имеет ключа fields, то он обязан иметь ключи value и block_fields
  // * это значит, что если form[field.name] === value, тогда открываются block_fields и их также нужно проверить
  // *, но если form[field.name] !== value, тогда просто возвращается true, то есть поле заполнено, но не открывает других закрытых полей

  if (Object.keys(form).length) {
    if (typeof field === 'string' && form[field]) {
      return isFieldFilled(form, field)
    } else if (typeof field === 'number' && form[field]) {
      return isFieldFilled(form, field)
    } else if (typeof field === 'object') {
      if (field.fields?.length) {
        // object looks like {name: 'subjects', 'fields': ['special_data']}
        let all_objects = form[field.name] // new form
        if (field.filter) {
          all_objects = form[field.name].filter(obj => obj[field.filter.key] === field.filter.value)
        }

        return all_objects.every(el => {
          // go over all nested fields in object
          return field.fields.every(f => {
            if (typeof f === 'string') {
              return isFieldFilled(el, f)
            } else if (typeof f === 'object') {
              return checkFieldStatus(el, f)
            }
          })
        })
      } else if (field.value && field.block_fields) {
        // object looks like {name: 'is_special_data', value: 'обрабатываем', block_fields: ['auto_desicio']}
        // need to check this fields if not open
        // field может быть как строкой - 'auto_decision', либо как объектом {name: 'docs', filter: {field: 'name', value: 'специальные'}}
        // в первом случае мы просто проверяем через isFieldFilled, во втором фильтруем и проверяем наличие length
        // здесь мы наткнулись на block_fields
        return countBlockFieldsRecursive(form, field, 0, 0, 'bool')
      }
    }
  }
}

function isFieldFilled(form, field) {
  if (typeof form[field] === 'object') {
    if (form[field] === null) {
      return false
    }
    if (form[field].length === 'undefined') {
      return !!form[field]
    }
  } else if (typeof form[field] === 'string') {
    return form[field].replace(/[\s+;]/g, '').length > 0
  } else if (typeof form[field] === 'number') {
    return !!form[field]
  } else if (typeof form[field] === 'boolean') {
    return true
  }
  return form[field].length > 0
}

export { getFormSectionStatus }
