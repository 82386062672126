export default {
  SET_WORKSTATIONS(state, forms) {
    state.workstations = forms
  },

  SET_WORKSTATION(state, form) {
    state.workstation = form
  },

  UPDATE_WORKSTATION(state, new_data) {
    state.workstation = { ...state.workstation, ...new_data }
  },

  DELETE_WORKSTATION(state, id) {
    state.workstations.splice(
      state.workstations.findIndex(s => s.id === id),
      1,
    )
  },

  ADD_WORKSTATION(state, workstation) {
    state.workstations.push(workstation)
  },

  ADD_EMPLOYEE_UNIT(state, employee_unit) {
    state.workstation.employee_units.push(employee_unit)
  },

  UPDATE_EMPLOYEE_UNIT(state, data) {
    const employee_id = state.workstation.employee_units.findIndex(el => el.id === data.id)
    state.workstation.employee_units[employee_id] = { ...state.workstation.employee_units[employee_id], ...data }
  },

  ADD_THIRD_PARTY_UNIT(state, third_party_unit) {
    state.workstation.third_party_units.push(third_party_unit)
  },

  UPDATE_THIRD_PARTY_UNIT(state, data) {
    const tp_id = state.workstation.third_party_units.findIndex(el => el.id === data.id)
    state.workstation.third_party_units[tp_id] = { ...state.workstation.third_party_units[tp_id], ...data }
  },
}
