<template>
  <div class="add_file_section">
    <div v-if="!file?.filename && formatCheck(filetype)">
      <label
        v-if="!file?.link?.length"
        class="file_label"
        @click="show_add_link = true"
      >
        <div
          v-if="!show_add_link"
          id="add_link_file"
          style="cursor: pointer"
          class="file_icon"
        >
          <img src="@/assets/img/plus-icon.svg" />
        </div>
        <div class="file_text">
          <p>{{ $t('documents.add_link.title') }}</p>
        </div>
      </label>
      <div v-if="show_add_link">
        <text-input
          id="type_link"
          v-model="link"
          type="text"
        />

        <div
          style="margin-bottom: 20px"
          class="modal_bottom_block"
        >
          <div class="buttons_group">
            <button
              id="add_link"
              class="success"
              @click="add"
            >
              {{ $t('general.save') }}
            </button>
            <button
              id="default"
              class="default"
              @click="show_add_link = false"
            >
              {{ $t('general.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!show_add_link">
      <input
        :id="`file_input_${tagId}`"
        ref="file_input"
        type="file"
        name="file_input"
        class="inputfile"
        @change="add"
      />
      <label
        v-if="!file"
        :for="`file_input_${tagId}`"
        class="file_label"
      >
        <div class="file_icon">
          <img src="@/assets/img/upload-icon.svg" />
        </div>
        <div class="file_text">
          <p class="file_title">{{ label_name }}</p>
          <p
            v-if="help_text"
            class="description"
          >
            {{ help_text }}
          </p>
          <p class="description">{{ formats }}</p>
        </div>
      </label>
    </div>
    <div
      v-if="file && use_name_date"
      class="input_group two-columns"
    >
      <text-input-emit
        :value="file.document_name"
        :tagId="`document_name_${tagId}`"
        :field_name="`document_name`"
        :label_name="$t('document.number')"
        @update="updateForm"
      ></text-input-emit>

      <div class="input_section">
        <p class="input_name">{{ $t('document.date') }}</p>
        <datepicker
          :id="`document_date_${tagId}`"
          v-model="file.document_date"
          class="date_input"
          :enableTimePicker="false"
          :format="`dd-MM-yyyy`"
          locale="ru-RU"
          value-type="format"
          :clearable="true"
          :placeholder="$t('document.ph')"
          @update:modelValue="updateForm('document_date', $event)"
        ></datepicker>
      </div>
    </div>

    <div
      v-if="file"
      :class="['added_doc', use_comments ? 'doc_section_for_three' : '']"
    >
      <!-- Название документа или ссылка на документ -->
      <div :class="['name', use_comments ? 'name_sec' : '']">
        <a
          v-if="!file?.link"
          :href="`${server}/api/v2/documents/${file.uuid}/download/`"
          target="_blank"
          download
        >
          <p v-if="formatCheck(filetype)">{{ file.filename }}</p>
          <img
            v-else
            :src="`${server}/api/v2/documents/${file.uuid}/download/`"
            alt=""
          />
        </a>
        <a
          :href="file?.link"
          target="_blank"
        >
          <p class="link_to_file">{{ file?.link }}</p>
        </a>
      </div>
      <!-- Комментарий к документу -->
      <document-comment
        v-if="use_comments"
        :document="file"
      ></document-comment>
      <!-- Кнопка Удалить -->
      <div
        :class="['remove_btn', use_comments ? 'comments_active' : '']"
        @click="show_delete_file = true"
      >
        {{ $t('general.delete') }}
      </div>
    </div>

    <delete-modal
      :show="show_delete_file"
      :title="$t('general.modal.delete.doc.title')"
      :desc="$t('general.modal.delete.doc.desc')"
      @close="show_delete_file = false"
      @approve="deleteFile"
    ></delete-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import DocumentComment from '@/views/documents/DocumentComment.vue'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import TextInputEmit from '@/components/global/inputs/TextInputEmit.vue'
import TextInput from '@/components/global/inputs/TextInput.vue'
import DeleteModal from '@/components/global/modals/deleteModal.vue'

export default {
  name: 'DocumentLinkInput',
  components: {
    DeleteModal,
    TextInput,
    TextInputEmit,
    Datepicker,
    DocumentComment,
  },

  props: {
    uuid: String,
    field_name: {
      type: String,
      default: null,
    },
    filetype: {
      type: String,
      default: 'doc',
    },
    label_name: {
      type: String,
      default: null,
    },
    help_text: {
      type: String,
      default: null,
    },
    tagId: {
      type: String,
      default: null,
    },
    use_comments: {
      type: Boolean,
      default: false,
    },
    use_name_date: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show_delete_file: false,
      show_add_link: false,
      link: '',
    }
  },

  async mounted() {
    if (this.uuid) {
      await this.fetchDocument({ uuid: this.uuid })
    }
  },

  computed: {
    ...mapState(['server']),
    ...mapState('company', ['documents']),

    file() {
      return this.documents[this.documents.findIndex(doc => doc.uuid === this.uuid)]
    },

    formats() {
      const formatMappings = {
        doc: 'pdf, txt, doc, docx',
        xls: 'pdf, txt, doc, docx, xls, xlsx',
      }
      const other_formats = 'jpg, jpeg, png, gif'
      const formats = formatMappings[this.filetype]
      return `${this.$t('general.access_formats')}${formats ? formats : other_formats}`
    },
  },

  methods: {
    ...mapMutations(['DANGER_TOAST']),
    ...mapActions('company', ['fetchDocument', 'addDocument', 'deleteDocument', 'updateDocument']),

    formatCheck(filetype) {
      return ['doc', 'xls'].includes(filetype)
    },

    async add() {
      let payload = { filetype: this.filetype, file: null, link: null }
      if (this.link) {
        payload = { ...payload, link: this.link }
        this.link = ''
        this.show_add_link = false
      } else if (this.$refs.file_input) payload = { ...payload, file: this.$refs.file_input.files[0] }

      if (payload.link || payload.file) {
        const result = await this.addDocument(payload)
        if (result) {
          this.$emit('update', this.field_name, result.uuid)
          this.$refs.file_input.value = null
        }
      }
    },

    updateForm(field_name, value) {
      if (['string', 'object'].includes(typeof value)) {
        let val = field_name === 'document_date' ? this.getValidDate(value) : value
        this.updateDocument({ field_name: field_name, value: val, uuid: this.uuid })
      }
    },

    getValidDate(date) {
      if (date === null) return null
      let conf = new Date(date)
      return `${conf.getFullYear()}-${conf.getMonth() + 1}-${conf.getDate()}`
    },

    async deleteFile() {
      this.$emit('delete', this.field_name, this.uuid)
      this.show_delete_file = false
    },
  },
}
</script>
