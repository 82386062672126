import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,

  state: {
    system_servers_loaded: false,
    system_servers: [],
    system_server: {},
    segments: [],
    server_addresses: [],
  },

  actions,
  mutations,
}
