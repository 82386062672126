export default {
  hasAccess: state => {
    return state.user.is_analyst || state.user.is_client
  },

  disableAdding: state => {
    return !state.user.is_analyst && !state.user.is_client && !state.user.is_student && !state.user.is_superuser
  },

  //Проверяем правильность ввода email
  checkEmail: () => email => {
    return !!email.match(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/g,
    )
  },

  // Пароль из букв, цифр и как минимум одного специального символа @!#$%^&* минимальная длина пароля 10 символов
  checkPassword: () => password => {
    return !!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{10,}$/)
  },
}
