export default {
  SET_SYSTEM_SERVERS(state, forms) {
    state.system_servers = forms
    state.system_servers_loaded = true
    // Добавляем сегменты в state если они есть на серверах
    const seg = [...new Set(state.system_servers.map(s => s.segment).filter(segment => segment !== ''))]
    if (seg?.length) {
      state.segments = Array(seg[0])
    }
    // Добавляем адреса размещения серверов в state если указаны в серверах
    const address = [...new Set(state.system_servers.map(s => s.server_address).filter(system => system !== ''))]
    if (address?.length) {
      state.server_addresses = address
    }
  },

  SET_SYSTEM_SERVER(state, form) {
    state.system_server = form
  },

  ADD_SEGMENT(state, new_segment) {
    state.segments.push(new_segment)
  },

  UPDATE_SYSTEM_SERVER(state, new_data) {
    state.system_server = { ...state.system_server, ...new_data }
  },

  DELETE_SYSTEM_SERVER(state, id) {
    state.system_servers.splice(
      state.system_servers.findIndex(s => s.id === id),
      1,
    )
  },

  ADD_SYSTEM_SERVER(state, system_server) {
    state.system_servers.push(system_server)
  },
}
