<template>
  <section
    v-click-outside="closeBoard"
    class="input_section"
  >
    <label>
      <p class="input_name">{{ field_name }}</p>

      <div class="text_input">
        <input
          ref="input"
          :value="getValue()"
          type="text"
          @input="filterList"
          @click="show = true"
        />

        <transition name="fastfade">
          <div
            v-if="show"
            class="input_dropdown"
          >
            <div class="input_dropdown__title">
              <span>Показано {{ start_typing ? filtered_data.length : data.length }}</span> из {{ data.length }}
            </div>

            <div
              v-for="(elem, index) in filtered_data"
              :key="index"
              class="input_dropdown__elem"
              @click="selectElem(elem)"
            >
              <span>{{ elem[field] }}</span>
            </div>
          </div>
        </transition>
      </div>
    </label>
  </section>
</template>

<script>
/*
* Этот компонент нужен для выпадающих элементов и выбора из этого списка, а также поиска по этому списку
* Когда focus на инпуте показывается выпадающее меню, при вводе эти элементы фильтруются и сверху показывается плашка
* с "Показано 4 из 36 элементов"

* есть 2 варианта - отдается Object и поле field, которое будет отображаться и по нему будет фильтроваться
* или есть строка и поле ['Данные1', 'Данные2'] по которым также будет фильтроваться
*/
export default {
  name: 'AutoComplete',

  props: {
    modelValue: {
      type: [Object, String],
    },
    field: String, // * Поле, которое будет отображаться и по которому будет фильтроваться
    data: Array,
    field_name: String,
  },

  data() {
    return {
      show: false,
      start_typing: false,
      filtered_data: [],
    }
  },

  computed: {
    isModelObject() {
      return typeof this.modelValue === 'object'
    },
  },

  mounted() {
    this.filtered_data = this.data
  },

  methods: {
    closeBoard() {
      this.show = false
    },

    filterList(e) {
      this.start_typing = true
      if (this.isModelObject) {
        this.filtered_data = this.data.filter(obj =>
          obj[this.field].toLowerCase().includes(e.target.value.toLowerCase()),
        )
      }
    },

    getValue() {
      // * так как нам приходит объект нужно оттуда данные доставать
      if (typeof this.modelValue === 'object') {
        return this.modelValue[this.field]
      }
    },

    selectElem(elem) {
      this.closeBoard()
      this.$refs.input.blur()
      this.$emit('select', elem)
    },
  },
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/general.sass"
</style>
